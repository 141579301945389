<!--
 * @Author: Ocean
 * @Description: 告警统计-柱状图
-->
<template>
  <div :id="barLineChartsId + 'box'" class="bar-line-charts-order">
    <div v-if="!isShowLoad" id="barLineChartsId"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcLineCharts',
    props: {
      // 图表数据-服务端提供
      data: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isShowLoad: true,
        barLineChartsId: `barLineChartsId-${Math.random().toString(36).substring(3, 8)}`, // 组件id
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler() {
          console.log(this.data, 'this.data===告警次数统计');
          this.isShowLoad = false;
          this.$nextTick(() => {
            this.drawBar();
          });
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        // 在div发生改变时调整echarts的尺寸
        this.myObserver = new ResizeObserver(() => {
          setTimeout(() => {
            if (this.myChart) {
              this.myChart.resize();
            }
          });
        });
        this.myObserver.observe(document.getElementById(`${this.barLineChartsId}box`));
      });
    },
    created() {},
    methods: {
      /**
       * 初始化图表
       */
      drawBar() {
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById(`${this.barLineChartsId}box`));
        const option = {
          tooltip: {
            show: true,
            trigger: 'axis',
            borderWidth: 0,
            borderRadius: 2,
            padding: 10,
            // extraCssText: 'box-shadow: none;',
            position(point, params, dom, rect, size) {
              // console.log(point, size, 'point size===');
              // 显示位置
              return [point[0] + 10, point[1] - size.contentSize[1] + 5];
            },
            formatter: params => {
              // 显示样式
              // console.log('params+++', params);
              let html = `<div class="tbox"><div>${params[0].name}</div>`;
              params.forEach(item => {
                html += `<div class="showBox">
                          <div style="display:flex;">
                              <span style="flex:1;color:#005BFF">${item.seriesName}：</span>
                              <span style="float:right;">${item.value}次</span>
                          </div>
                      </div>`;
              });
              html += '</div>';
              return html;
            },
          },
          legend: {
            orient: 'horizontal',
            x: 'center',
            y: 'top',
            itemGap: 30,
            icon: 'circle',
            data: [],
          },
          grid: {
            top: '50px',
            bottom: '20px',
            left: '20px',
            right: '30px',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: this.data.map(item => item.month),
            },
          ],

          yAxis: [
            {
              type: 'value',
              name: '告警次数(次)',
              position: 'left',
              nameTextStyle: {
                align: 'center',
                color: '#4A4A4A',
                fontSize: 14,
              },
              // axisLine: {
              //   show: true,
              //   lineStyle: {
              //     color: 'rgba(255,255,255,0.15)',
              //   },
              // },
              // axisLabel: {
              //   show: true,
              //   textStyle: {
              //     color: '#4A4A4A',
              //     fontSize: 12,
              //   },
              // },
              splitLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#E2E0E0',
                },
              },
            },
          ],
          series: [
            {
              name: '告警次数',
              type: 'bar',
              stack: '订单',
              showAllSymbol: true,
              symbol: 'circle',
              symbolSize: 6,
              barWidth: 17,
              barGap: '0%',
              lineStyle: {
                normal: {
                  color: '#4d8cfd',
                  shadowColor: 'rgba(0, 0, 0, .3)',
                },
              },
              label: {
                show: false,
              },
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#0091FF', // 渐变起始颜色
                    },
                    {
                      offset: 1,
                      color: '#B620E0', // 渐变结束颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              data: this.data.map(item => item.count),
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
        this.myChart = myChart;
      },
    },
    beforeDestroy() {
      if (this.myObserver && document.getElementById(`${this.barLineChartsId}box`)) {
        this.myObserver.unobserve(document.getElementById(`${this.barLineChartsId}box`));
      }
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  .bar-line-charts-order {
    width: 100%;
    height: 348px;
    .bar-loading {
      margin-top: 60px;
    }
  }
</style>
