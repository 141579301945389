<!--
 * @Author: Ocean
 * @Description: 告警统计-仪表盘
-->
<template>
  <div :id="gaugeId + 'box'" class="progress-line-charts">
    <div v-if="!isShowLoad" id="gaugeId"></div>
    <div v-else class="bar-loading">
      <div class="ykc-loading bar-loading">
        <div class="k-line k-line11-1"></div>
        <div class="k-line k-line11-2"></div>
        <div class="k-line k-line11-3"></div>
        <div class="k-line k-line11-4"></div>
        <div class="k-line k-line11-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  export default {
    name: 'YkcLineCharts',
    props: {
      // 图表数据-服务端提供
      data: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        isShowLoad: true,
        gaugeId: `gaugeId-${Math.random().toString(36).substring(3, 8)}`, // 组件id
        labelColor1: '',
        labelColor2: '',
        axisLineBackColor: [],
      };
    },
    watch: {
      data: {
        // 首次绑定是否执行handle
        immediate: true,
        deep: true,
        handler() {
          console.log(this.data, 'FDSSSSSSSSSSSSSSSSSS');
          this.isShowLoad = false;
          this.$nextTick(() => {
            this.drawBar();
          });
        },
      },
    },
    mounted() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    created() {},
    methods: {
      /**
       * 初始化图表
       */
      drawBar() {
        // 基于准备好的dom，初始化echarts实例
        const myChart = echarts.init(document.getElementById(`${this.gaugeId}box`));
        const option = {
          series: [
            {
              type: 'gauge',
              center: ['50%', '60%'],
              startAngle: 240,
              endAngle: -60,
              min: 0,
              max: 100,
              itemStyle: {
                // 圆弧刻度的颜色,这样只是设置一个颜色
                // color: '#ffabcd',
                // 颜色渐变
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: this.setGressBarColor(this.data.key),
                  global: false, // 默认为 false
                },
              },
              progress: {
                show: true,
                width: 10,
              },
              pointer: {
                show: false,
              },
              axisLine: {
                shwo: true,
                lineStyle: {
                  width: 10,
                  color: this.setGressBackColor(this.data.key) || [],
                },
              },
              axisTick: {
                show: false,
                animation: true, // 开启刻度过渡效果,
              },
              splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                  width: 2,
                  color: '#999',
                },
              },
              axisLabel: {
                show: false,
                distance: 25,
                color: '#999',
                fontSize: 20,
              },
              anchor: {
                show: false,
                showAbove: true,
                size: 25,
                itemStyle: {
                  borderWidth: 10,
                  color: '#6B6CFF',
                },
              },
              detail: {
                valueAnimation: true,
                formatter: '{value}%',
                fontSize: 20,
                fontWeight: 'bolder',
                offsetCenter: [0, '10%'],
              },
              data: [
                {
                  value: this.data.value,
                },
              ],
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
        this.myChart = myChart;
      },
      /**
       * 设置进度条颜色
       */
      setGressBarColor(key) {
        if (key === '硬件故障') {
          this.labelColor1 = '#009CFF';
          this.labelColor2 = '#6B6CFF';
          this.axisLineBackColor = [[1, 'rgba(0,145,255,0.15)']];
        } else if (key === '充电异常终止') {
          this.labelColor1 = '#FF6714';
          this.labelColor2 = '#F7A400';
        } else if (key === '电桩离线') {
          this.labelColor1 = '#1BC454';
          this.labelColor2 = '#33F0A0';
        } else if (key === '充电启动失败') {
          this.labelColor1 = '#02C1FF';
          this.labelColor2 = '#5AD7FF';
        } else {
          this.labelColor1 = '#02C1FF';
          this.labelColor2 = '#5AD7FF';
        }
        return [
          {
            offset: 0,
            color: this.labelColor1, // 0% 处的颜色
          },
          {
            offset: 1,
            color: this.labelColor2, // 100% 处的颜色
          },
        ];
      },
      /**
       * 设置进度条背景颜色
       */
      setGressBackColor(key) {
        if (key === '硬件故障') {
          this.axisLineBackColor = [1, 'rgba(0,145,255,0.15)'];
        } else if (key === '充电异常终止') {
          this.axisLineBackColor = [1, 'rgba(250,100,0,0.15)'];
        } else if (key === '电桩离线') {
          this.axisLineBackColor = [1, 'rgba(29,214,100,0.15)'];
        } else if (key === '充电启动失败') {
          this.axisLineBackColor = [1, 'rgba(0,145,255,0.15)'];
        } else {
          this.axisLineBackColor = [1, 'rgba(30,200,255,0.15)'];
        }
        return [this.axisLineBackColor];
      },
    },
    beforeDestroy() {
      if (this.myChart) {
        this.myChart.dispose();
      }
    },
  };
</script>
<style lang="scss">
  .progress-line-charts {
    width: 100%;
    width: 150px;
    height: 150px;
  }
</style>
