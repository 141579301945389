<!-------------------------------------------------
description:  运维中心 告警信息 告警统计
/**
 *@author ocean
 *@date 2024/2/23
*/
--------------------------------------------------->
<template>
  <scroll-layout class="page-container">
    <!--筛选框-->
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div class="nav-box">
      <div class="nav-card" :style="{ background: '#1bc454' }">
        <div class="nav-card-text">
          <p>
            <count-to-percent
              :duration="2000"
              :endVal="Number(devicedStatusData.alarmTotal)"></count-to-percent>
          </p>
          <p>告警总量</p>
        </div>
      </div>
      <div class="nav-card" :style="{ background: '#FFB100' }">
        <div class="nav-card-text">
          <p>
            <count-to-percent
              :duration="2000"
              :endVal="Number(devicedStatusData.faultRate)"></count-to-percent>
            %
          </p>
          <p>
            设备故障率
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                设备故障率:
                <br />
                根据时间范围，所有枪的故障总时长/ 时间基数*枪总数
              </div>
              <img :src="toolipImageUrl" alt="" />
            </el-tooltip>
          </p>
        </div>
      </div>
      <div class="nav-card" :style="{ background: '#02C1FF' }">
        <div class="nav-card-text">
          <p>
            <count-to-percent
              :duration="2000"
              :endVal="Number(devicedStatusData.offRate)"></count-to-percent>
            %
          </p>
          <p>
            设备离线率
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                设备离线率:
                <br />
                根据时间范围，所有枪的离线总时长/时间基数*枪总数
              </div>
              <img :src="toolipImageUrl" alt="" />
            </el-tooltip>
          </p>
        </div>
      </div>
      <div class="nav-card" :style="{ background: '#7C7EFF' }">
        <div class="nav-card-text">
          <p>
            <count-to-percent
              :duration="2000"
              :endVal="Number(devicedStatusData.availableRate)"></count-to-percent>
            %
          </p>
          <p>
            设备可用率
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                设备可用率:
                <br />
                根据时间范围，所有枪的【空闲时长 + 充电中时长 】/ 时间基数* 枪总数
              </div>
              <img :src="toolipImageUrl" alt="" />
            </el-tooltip>
          </p>
        </div>
      </div>
      <div class="nav-card" :style="{ background: ' #0778F6' }">
        <div class="nav-card-text">
          <p>
            <count-to-percent
              :duration="2000"
              :endVal="Number(devicedStatusData.useRate)"></count-to-percent>
            %
          </p>
          <p>
            设备利用率
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                设备利用率:
                <br />
                根据时间范围，所有枪的充电总时长/时间基数*枪总数
              </div>
              <img :src="toolipImageUrl" alt="" />
            </el-tooltip>
          </p>
        </div>
      </div>
    </div>
    <!-- 排行榜 -->
    <div class="middle-box">
      <div class="rank-left">
        <div class="rank-left-title">桩企排行</div>
        <div class="rank-left-head">
          <p class="margin-right-26">排名</p>
          <p class="flex1">桩企名称</p>
          <p class="with_cap_80">告警次数</p>
        </div>
        <div class="rank-left-list">
          <div
            class="rank-left-body"
            :class="{ b_scroll: brandModuleTop.length > 5 }"
            v-if="brandModuleTop.length > 0">
            <div class="rank-left-content" v-for="(item, index) in brandModuleTop" :key="index">
              <p
                class="width_25 margin-right-26"
                :class="[1, 2, 3].includes(index + 1) ? 'round50' : ''">
                {{ item.id }}
              </p>
              <p class="width-300" :class="[1, 2, 3].includes(index + 1) ? 'color-blue' : ''">
                {{ item.brandModuleName || '——' }}
              </p>
              <div class="progress-box">
                <p class="progress-bar">
                  <span class="progress-bar-foin" :style="{ width: item.ratio + '%' }"></span>
                </p>
              </div>
              <p class="with_cap_80" :class="[1, 2, 3].includes(index + 1) ? 'color-blue' : ''">
                {{ item.count }}
              </p>
            </div>
          </div>
          <div v-else class="ykc-table-empty">
            <img :src="emptyImageUrl" alt="table-empty" />
            <span>暂无数据</span>
          </div>
        </div>
      </div>
      <div class="rank-right">
        <div class="rank-left-title">场站排行</div>
        <div class="rank-left-head">
          <p class="margin-right-95">排名</p>
          <p class="flex1">场站名称</p>
          <p class="with_cap_80">告警次数</p>
        </div>
        <div class="rank-right-list">
          <el-carousel
            :interval="5000"
            type="vertical"
            height="270px"
            :motion-blur="true"
            v-if="stationTop.length > 0">
            <el-carousel-item v-if="stationTop.slice(0, 5).length > 0">
              <div
                class="rank-right-content"
                v-for="(item, index) in stationTop.slice(0, 5)"
                :key="index">
                <p
                  class="margin-right-95"
                  :class="[1, 2, 3].includes(item.id) ? 'color-blue' : ''"
                  style="line-height: 25px">
                  NO.{{ item.id }}
                </p>
                <p
                  class="flex1 text-index10"
                  :class="[1, 2, 3].includes(item.id) ? 'color-blue' : ''">
                  {{ item.stationName || '——' }}
                </p>
                <p class="with_cap_80" :class="[1, 2, 3].includes(item.id) ? 'color-blue' : ''">
                  {{ item.count }}
                </p>
              </div>
            </el-carousel-item>

            <el-carousel-item v-if="stationTop.slice(5, 10).length > 0">
              <div
                class="rank-right-content"
                v-for="(item, index) in stationTop.slice(5, 10)"
                :key="index">
                <p class="margin-right-95" style="line-height: 25px">NO.{{ item.id }}</p>
                <p class="flex1 text-index10">
                  {{ item.stationName || '——' }}
                </p>
                <p class="with_cap_80">
                  {{ item.count }}
                </p>
              </div>
            </el-carousel-item>
          </el-carousel>

          <div v-else class="ykc-table-empty">
            <img :src="emptyImageUrl" alt="table-empty" />
            <span>暂无数据</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 统计 -->
    <div class="statis-wrap">
      <!-- 告警次数统计 -->
      <div class="statis-wrap-left">
        <p class="statis-wrap-left-title">告警次数统计</p>
        <bar-line-charts v-if="barlineData.length > 0" :data="barlineData"></bar-line-charts>
        <div v-else class="ykc-table-empty">
          <img :src="emptyImageUrl" alt="table-empty" />
          <span>暂无数据</span>
        </div>
      </div>
      <!-- 告警类型统计 -->
      <div class="statis-wrap-right">
        <p class="statis-wrap-right-title">告警类型统计</p>
        <div class="progress-wrap">
          <div class="progress-li" v-for="(item, index) in alarmTypeData" v-bind:key="index">
            <progress-charts :data="{ value: item.ratio, key: item.alarmType }"></progress-charts>
            <p>{{ item.alarmType }}</p>
          </div>
        </div>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { getLastYear } from '@/utils';
  import CountToPercent from '../components/CountToPercent.vue';
  import BarLineCharts from '../components/BarLineCharts.vue';
  import ProgressCharts from '../components/ProgressCharts.vue';
  import { omManagementApi } from '@/service/apis';

  export default {
    name: 'alarmStatistics',
    data() {
      return {
        searchParams: {
          date: [],
          endTime: '',
          startTime: '',
        },
        // 设备状态信息
        devicedStatusData: {
          alarmTotal: 0, // 告警总量
          faultRate: 0, // 设备故障率
          offRate: 0, // 设备离线率
          availableRate: 0, // 设备可用率
          useRate: 0, // 设备利用率
        },
        // 桩企排行
        brandModuleTop: [],
        // 场站排行
        stationTop: [],
        // 告警次数统计
        barlineData: [],
        // 告警类型统计
        alarmTypeData: [],
        emptyImageUrl: require('@/assets/images/no-data.png'),
        toolipImageUrl: require('@/assets/images/toolip.png'),
      };
    },
    components: {
      CountToPercent,
      BarLineCharts,
      ProgressCharts,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            type: 'monthrange',
            label: '时间选择 ',
            placeholder: '请选择时间',
          },
        ];
      },
    },
    destroyed() {},
    created() {
      this.setInitDate();
      this.InitDataFunc();
    },
    methods: {
      /**
       * 设置默认时间
       */
      setInitDate() {
        const monthStr = getLastYear(6, 1);
        const startDateStr = monthStr.startTime;
        const endDateStr = monthStr.endTime;

        this.searchParams.date = [startDateStr, endDateStr];
        this.searchParams.startTime = startDateStr;
        this.searchParams.endTime = endDateStr;
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        Object.assign(this.searchParams, searchData);
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.date;
        }
        console.log('searchData++++', searchData);
        this.InitDataFunc();
      },
      InitDataFunc() {
        const startYear = this.searchParams.startTime.split('-')[0] || '';
        const startMonth = this.searchParams.startTime.split('-')[1] || '';
        const endYear = this.searchParams.endTime.split('-')[0] || '';
        const endMonth = this.searchParams.endTime.split('-')[1] || '';
        // console.log(startYear, startMonth, endYear, endMonth);
        if (this.isMoreThan12Months(startYear, startMonth, endYear, endMonth)) {
          this.$message.warning('时间区间最大支持12个月!');
          return;
        }

        this.queryStatusStatisticInit();
        this.queryRankingListInit();
        this.alarmQueryMonthCountInit();
        this.alarmQueryTypeCountInit();
      },
      /**
       * 重新计算传入的时间
       * @param {*} startDate
       * @param {*} endDate
       */
      getMonthDiff(startYear, startMonth, endYear, endMonth) {
        const startDate = new Date(startYear, startMonth - 1); // 月份从0开始，需要减1
        const endDate = new Date(endYear, endMonth - 1); // 月份从0开始，需要减1

        const diff =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          (endDate.getMonth() - startDate.getMonth());

        return diff;
      },
      /**
       * 判断时间月份是否大于12个月
       * @param {*} startDate
       * @param {*} endDate
       */
      isMoreThan12Months(startYear, startMonth, endYear, endMonth) {
        const monthDiff = this.getMonthDiff(startYear, startMonth, endYear, endMonth);

        return monthDiff > 12;
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        this.setInitDate();
        console.log('清空查询', form);
      },
      /**
       * 查询设备状态统计信息
       */
      queryStatusStatisticInit() {
        const reqParams = {
          ...this.searchParams,
        };
        omManagementApi
          .alarmQueryStatusStatistic(reqParams)
          .then(res => {
            this.devicedStatusData = { ...res };
          })
          .catch(() => {
            this.devicedStatusData = {};
          });
      },
      /**
       * 查询告警排行榜
       */
      queryRankingListInit() {
        const reqParams = {
          ...this.searchParams,
        };
        omManagementApi
          .alarmQueryRankingList(reqParams)
          .then(res => {
            this.brandModuleTop = (res.brandModuleTop || []).map((obj, index) => {
              return { ...obj, id: index + 1 };
            }); // 电桩品牌型号排行榜
            this.stationTop = (res.stationTop || []).map((obj, index) => {
              return { ...obj, id: index + 1 };
            }); // 场站排行榜
          })
          .catch(() => {
            this.brandModuleTop = []; // 电桩品牌型号排行榜
            this.stationTop = []; // 场站排行榜
          });
      },
      /**
       * 查询告警次数统计【包含告警总量】
       */
      alarmQueryMonthCountInit() {
        const reqParams = {
          ...this.searchParams,
        };
        omManagementApi
          .alarmQueryMonthCount(reqParams)
          .then(res => {
            this.$nextTick(() => {
              console.log(res, '+++');
              this.devicedStatusData.alarmTotal = res.alarmTotal || 0; // 告警总量
              this.barlineData = res.monthCountList || [];
            });
          })
          .catch(() => {
            this.barlineData = [];
          });
      },
      /**
       * 查询告警类型统计
       */
      alarmQueryTypeCountInit() {
        const reqParams = {
          ...this.searchParams,
        };
        omManagementApi
          .alarmQueryTypeCount(reqParams)
          .then(res => {
            this.alarmTypeData = res || [];
          })
          .catch(() => {
            this.alarmTypeData = [];
          });
      },
    },
  };
</script>

<style lang="scss">
  .nav-box {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    .nav-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      height: 116px;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      border-radius: 4px;
      margin-right: 14px;
      .nav-card-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        p:nth-child(1) {
          margin: 0;
          color: #ffffff;
          margin-bottom: 15px;
          font-size: 28px;
          display: flex;
          span {
            display: block;
          }
        }
        p:nth-child(2) {
          margin: 0;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          position: relative;
        }
        img {
          width: 15px;
          height: 15px;
          position: absolute;
          z-index: 10000;
          right: -18px;
          top: 2px;
          cursor: pointer;
        }
      }
    }
  }
  .middle-box {
    display: flex;
    margin-top: 10px;
    .rank-left,
    .rank-right {
      position: relative;
      width: 50%;
      background: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      border-radius: 4px;

      padding-bottom: 28px;
      .rank-left-title {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin-top: 18px;
      }
      .rank-left-head,
      .rank-left-content,
      .rank-right-content {
        padding-left: 20px;
        display: flex;
        font-size: 14px;
      }
      .rank-left-head {
        padding-top: 10px;
      }
      @keyframes fadeInOut {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      .fade-in-out {
        animation: fadeInOut 5s ease-in-out;
      }
      .rank-right-content:hover {
        cursor: pointer;
        background: rgba(146, 146, 146, 0.1);
      }
    }

    .rank-left {
      margin-right: 10px;
      padding: 0 17px;
      box-sizing: border-box;
    }
    .rank-right {
      padding: 0 18px;
      box-sizing: border-box;
    }
  }
  .statis-wrap {
    display: flex;
    margin-top: 10px;
    .statis-wrap-left {
      width: 50%;
      background: #fff;
      padding: 0 17px;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      margin-right: 10px;
      &-title {
        margin: 0;
        margin-top: 18px;
      }
    }
    .statis-wrap-right {
      width: 50%;
      background: #fff;
      padding: 0 17px;
      box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
      display: flex;
      flex-direction: column;
      &-title {
        margin: 0;
        margin-top: 18px;
      }
      .progress-wrap {
        display: flex;
        justify-content: space-around;
        margin-top: 66px;
        .progress-li {
          text-align: center;
          p {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }

  .with_cap_80 {
    width: 80px;
    text-align: center;
  }
  .width_25 {
    width: 25px;
    text-align: center;
  }
  .color-blue {
    color: #005bff;
  }
  .margin-right-26 {
    margin-right: 26px;
  }
  .margin-right-95 {
    margin-right: 95px;
  }
  .flex1 {
    flex: 1;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .text-index10 {
    text-align: 10px;
  }
  .width-300 {
    width: 300px;
    text-indent: 10px;
  }
  .round50 {
    width: 25px;
    line-height: 25px;
    background: #005bff;
    border-radius: 50%;
    color: #fff;
    text-align: center;
  }
  .progress-box {
    width: 400px;
    padding: 0 5px;
    .progress-bar {
      width: 100%;
      background: rgba(0, 145, 255, 0.1);
      height: 12px;
      margin: 0;
      margin-top: 20px;
      .progress-bar-foin {
        width: 0%;
        height: 12px;
        display: block;
        background: linear-gradient(225deg, #79baff 0%, #0062f7 100%);
        animation: expandWidth 1s forwards;
      }
      @keyframes expandWidth {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
      }
    }
  }
  //桩企排行帧动画【自上而下滚动】
  @keyframes scrollTop {
    0% {
      transform: translate(0, 0, 0);
    }
    100% {
      transform: translate(0, -230px, 0);
    }
  }
  .rank-left-list {
    height: 270px;
    overflow: hidden;
    cursor: pointer;
  }

  .b_scroll {
    animation: 20s scrollTop linear infinite normal;
  }
  .b_scroll:hover {
    animation-play-state: paused; /* 移入元素时暂停动画 */
  }

  .rank-right-list {
    height: 270px;
    overflow: hidden;
  }
  .el-carousel__arrow--right,
  .el-carousel__arrow--left {
    display: none;
  }
  .el-carousel__indicators {
    display: none;
  }
</style>
